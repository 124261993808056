import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../components/Dashboard/Home/Home';
import Sidebar from '../components/Dashboard/Sidebar/Sidebar';
import Settings from '../components/Dashboard/Settings/Settings';
import PostScheduler from '../components/Dashboard/QuickPost/PostScheduler';
import LinkedInPostGenerator from '../components/Dashboard/GeneratePost/GeneratePost';
import ProtectedRoute from '../components/ProtectedRoute';
import PostDetails from '../components/Dashboard/PostHistory/PostQueue';
function Dashboard() {
  return (
    <div className="flex lg:flex-row scrollbar-hide lg:pt-2 lg:px-2 p-0 rounded-xl flex-col h-screen">
      <Sidebar />
      <div className="flex-1 scrollbar-hide overflow-auto">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                {' '}
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/quick-post"
            element={
              <ProtectedRoute>
                <PostScheduler />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-post"
            element={
              <ProtectedRoute>
                <LinkedInPostGenerator />
              </ProtectedRoute>
            }
          />
          <Route
            path="/post-history"
            element={
              <ProtectedRoute>
                <PostDetails />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default Dashboard;
